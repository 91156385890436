enum QUALIFICATION_CHOICES {
    PRIMARY_LOWER_MIDDLE_SCHOOL_CERTIFICATE = 'Licenza elem./media inf.',
    HIGH_SCHOOL_DIPLOMA = 'Diploma superiore',
    UNIVERSITY_DEGREE = 'Laurea',
}


enum PROVINCE_DOMICILE_CHOICES {
    AG = 'Agrigento', AL = 'Alessandria', AN = 'Ancona', AR = 'Arezzo', AP = 'Ascoli Piceno',
    AT = 'Asti',
    AV = 'Avellino', BA = 'Bari', BT = 'Barletta-Andria-Trani', BL = 'Belluno', BN = 'Benevento',
    BG = 'Bergamo', BI = 'Biella', BO = 'Bologna', BZ = 'Bolzano/Bozen', BS = 'Brescia',
    BR = 'Brindisi',
    CA = 'Cagliari', CL = 'Caltanissetta', CB = 'Campobasso', CI = 'Carbonia-Iglesias', CE = 'Caserta',
    CT = 'Catania', CZ = 'Catanzaro', CH = 'Chieti', CO = 'Como', CS = 'Cosenza', CR = 'Cremona',
    KR = 'Crotone', CN = 'Cuneo', EN = 'Enna', FM = 'Fermo', FE = 'Ferrara', FI = 'Firenze',
    FG = 'Foggia', FC = 'Forlì-Cesena', FR = 'Frosinone', GE = 'Genova', GO = 'Gorizia',
    GR = 'Grosseto',
    IM = 'Imperia', IS = 'Isernia', AQ = "L'Aquila", SP = 'La Spezia', LT = 'Latina', LE = 'Lecce',
    LC = 'Lecco', LI = 'Livorno', LO = 'Lodi', LU = 'Lucca', MC = 'Macerata', MN = 'Mantova',
    MS = 'Massa-Carrara', MT = 'Matera', VS = 'Medio Campidano', ME = 'Messina', MI = 'Milano',
    MO = 'Modena', MB = 'Monza e della Brianza', NA = 'Napoli', NO = 'Novara', NU = 'Nuoro',
    OG = 'Ogliastra', OT = 'Olbia-Tempio', OR = 'Oristano', PD = 'Padova', PA = 'Palermo',
    PR = 'Parma',
    PV = 'Pavia', PG = 'Perugia', PU = 'Pesaro e Urbino', PE = 'Pescara', PC = 'Piacenza',
    PI = 'Pisa',
    PT = 'Pistoia', PN = 'Pordenone', PZ = 'Potenza', PO = 'Prato', RG = 'Ragusa', RA = 'Ravenna',
    RC = 'Reggio di Calabria', RE = "Reggio nell'Emilia", RI = 'Rieti', RN = 'Rimini', RM = 'Roma',
    RO = 'Rovigo', SA = 'Salerno', SS = 'Sassari', SV = 'Savona', SI = 'Siena', SR = 'Siracusa',
    SO = 'Sondrio', TA = 'Taranto', TE = 'Teramo', TR = 'Terni', TO = 'Torino', TP = 'Trapani',
    TN = 'Trento', TV = 'Treviso', TS = 'Trieste', UD = 'Udine', AO = "Valle d'Aosta/Vallée d'Aoste",
    VA = 'Varese', VE = 'Venezia', VB = 'Verbano-Cusio-Ossola', VC = 'Vercelli', VR = 'Verona',
    VV = 'Vibo Valentia', VI = 'Vicenza', VT = 'Viterbo',
}

export const SICILIAN_PROVINCES = ['AG', 'CL', 'CT', 'EN', 'ME', 'PA', 'RG', 'SR', 'TP'];

enum AGE_RANGE_CHOICES {
    AGE_BT_18_22 = '18-22',
    AGE_BT_23_27 = '23-27',
    AGE_BT_28_32 = '28-32',
    AGE_BT_33_37 = '33-37',
    AGE_BT_38_42 = '38-42',
    AGE_GTE_43 = '43+',
}

enum PREGNANCY_WEEK_CHOICES {
    PW_1 = '1',
    PW_2 = '2',
    PW_3 = '3',
    PW_4 = '4',
    PW_5 = '5',
    PW_6 = '6',
    PW_7 = '7',
    PW_8 = '8',
    PW_9 = '9',
    PW_10 = '10',
    PW_11 = '11',
    PW_12 = '12',
    PW_13 = '13',
    PW_14 = '14',
    PW_15 = '15',
    PW_16 = '16',
    PW_17 = '17',
    PW_18 = '18',
    PW_19 = '19',
    PW_20 = '20',
    PW_21 = '21',
    PW_22 = '22',
    PW_23 = '23',
    PW_24 = '24',
    PW_25 = '25',
    PW_26 = '26',
    PW_27 = '27',
    PW_28 = '28',
    PW_29 = '29',
    PW_30 = '30',
    PW_31 = '31',
    PW_32 = '32',
    PW_33 = '33',
    PW_34 = '34',
    PW_35 = '35',
    PW_36 = '36',
    PW_37 = '37',
    PW_38 = '38',
    PW_39 = '39',
    PW_40 = '40',
    PW_41 = '41',
    PW_42 = '42',
}

export const QUALIFICATION_CHOICES_PAIRS = Object.keys(QUALIFICATION_CHOICES).map(
    (key) => ({
        key,
        value: QUALIFICATION_CHOICES[
            key as keyof typeof QUALIFICATION_CHOICES
        ],
    })
);

export const PROVINCE_DOMICILE_CHOICES_PAIRS = Object.keys(
    PROVINCE_DOMICILE_CHOICES
).map((key) => ({
    key,
    value: PROVINCE_DOMICILE_CHOICES[
        key as keyof typeof PROVINCE_DOMICILE_CHOICES
    ],
}));

export const AGE_RANGE_CHOICES_PAIRS = Object.keys(AGE_RANGE_CHOICES).map(
    (key) => ({
        key,
        value: AGE_RANGE_CHOICES[key as keyof typeof AGE_RANGE_CHOICES],
    })
);

export const PREGNANCY_WEEK_CHOICES_PAIRS = Object.keys(PREGNANCY_WEEK_CHOICES).map(
    (key) => ({
        key,
        value: PREGNANCY_WEEK_CHOICES[key as keyof typeof PREGNANCY_WEEK_CHOICES],
    })
);