import { Action as AnyAction } from '@reduxjs/toolkit';
import { getRandomUsernameFailed, getRandomUsernameSuccess, getUserProfileFailed, getUserProfileSuccess, signInFailed, signInSuccess, signUpFailed } from './auth.action';

export type User = {
    id: number;
    username: string;
    date_joined: string;
    userprofile: {
        qualification: string;
        province_domicile: string;
        pregnancy_week: number | null;
        post_birth_week: number | null;
        age_range: string;
        favorite_news: number[];
        first_survey_completed: boolean;
        last_survey_completed: boolean;
    };
};

export type AuthState = {
    readonly accessToken: string | null;
    readonly userProfile: User | null;
    readonly lastRandomUsername: string | null;
    readonly isLoading: boolean;
    readonly error: string | null;
};

const INITIAL_STATE: AuthState = {
    accessToken: null,
    userProfile: null,
    lastRandomUsername: null,
    isLoading: false,
    error: null,
};

export const authReducer = (state = INITIAL_STATE, action: AnyAction) => {
    if (getUserProfileSuccess.match(action) || signInSuccess.match(action)) {
        return { ...state, ...action.payload, error: null };
    }

    if (getRandomUsernameSuccess.match(action)) {
        return { ...state, lastRandomUsername: action.payload.username, error: null };
    }

    if (
        signInFailed.match(action) ||
        getUserProfileFailed.match(action) ||
        getRandomUsernameFailed.match(action) ||
        signUpFailed.match(action)
    ) {
        return { ...state, error: action.payload, accessToken: null, userProfile: null, lastRandomUsername: null };
    }

    return state;
};