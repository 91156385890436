import { User } from './auth.reducer';

import { AUTH_ACTION_TYPES } from './auth.types';

import {
    Action,
    ActionWithPayload,
    createAction,
    withMatcher,
} from '../../utils/reducer/reducer.utils';

export type SignInStart = ActionWithPayload<
    AUTH_ACTION_TYPES.SIGN_IN_START,
    { email: string; password: string; registration_id: string, onComplete: () => void; }
>;

export type SignInSuccess = ActionWithPayload<
    AUTH_ACTION_TYPES.SIGN_IN_SUCCESS,
    { accessToken: string; }
>;

export type SignInFailed = ActionWithPayload<
    AUTH_ACTION_TYPES.SIGN_IN_FAILED,
    string
>;

export type GetUserProfileStart = ActionWithPayload<
    AUTH_ACTION_TYPES.GET_USER_PROFILE_START,
    { accessToken: string; }
>;

export type GetUserProfileSuccess = ActionWithPayload<
    AUTH_ACTION_TYPES.GET_USER_PROFILE_SUCCESS,
    { accessToken: string; userProfile: User; }
>;

export type GetUserProfileFailed = ActionWithPayload<
    AUTH_ACTION_TYPES.GET_USER_PROFILE_FAILED,
    string
>;

export type RefreshUserProfile = ActionWithPayload<
    AUTH_ACTION_TYPES.REFRESH_USER_PROFILE,
    { accessToken: string; }>;

export type GetRandomUsernameStart = Action<
    AUTH_ACTION_TYPES.GET_RANDOM_USERNAME_START
>;

export type GetRandomUsernameSuccess = ActionWithPayload<
    AUTH_ACTION_TYPES.GET_RANDOM_USERNAME_SUCCESS,
    { username: string; }
>;

export type GetRandomUsernameFailed = ActionWithPayload<
    AUTH_ACTION_TYPES.GET_RANDOM_USERNAME_FAILED,
    string
>;

export type SetFirstSurveyCompletedStart = Action<
    AUTH_ACTION_TYPES.SET_FIRST_SURVEY_COMPLETED_START
>;

export type SetFirstSurveyCompletedSuccess = ActionWithPayload<
    AUTH_ACTION_TYPES.SET_FIRST_SURVEY_COMPLETED_SUCCESS,
    { accessToken: string; }
>;

export type SetFirstSurveyCompletedFailed = ActionWithPayload<
    AUTH_ACTION_TYPES.SET_FIRST_SURVEY_COMPLETED_FAILED,
    string
>;

export type SetLastSurveyCompletedStart = Action<
    AUTH_ACTION_TYPES.SET_LAST_SURVEY_COMPLETED_START
>;

export type SetLastSurveyCompletedSuccess = ActionWithPayload<
    AUTH_ACTION_TYPES.SET_LAST_SURVEY_COMPLETED_SUCCESS,
    { accessToken: string; }
>;

export type SetLastSurveyCompletedFailed = ActionWithPayload<
    AUTH_ACTION_TYPES.SET_LAST_SURVEY_COMPLETED_FAILED,
    string
>;

export type SetBirthOccurredStart = ActionWithPayload<
    AUTH_ACTION_TYPES.SET_BIRTH_OCCURRED_START, { onComplete: () => void; }
>;

export type SetBirthOccurredSuccess = ActionWithPayload<
    AUTH_ACTION_TYPES.SET_BIRTH_OCCURRED_SUCCESS,
    { accessToken: string; }
>;

export type SetBirthOccurredFailed = ActionWithPayload<
    AUTH_ACTION_TYPES.SET_BIRTH_OCCURRED_FAILED,
    string
>;

export type SignUpStart = ActionWithPayload<AUTH_ACTION_TYPES.SIGN_UP_START, {
    username: string;
    pin: string;
    qualification: string;
    domicile_province: string;
    age_range: string;
    initial_pregnancy_week: number | null;
    birth_date: string | null;
    onComplete: (
        { pregnancy_week, post_birth_week }:
            { pregnancy_week: number | null, post_birth_week: number | null; }) => void;
}>;

export type SignUpSuccess = Action<AUTH_ACTION_TYPES.SIGN_UP_SUCCESS>;

export type SignUpFailed = ActionWithPayload<
    AUTH_ACTION_TYPES.SIGN_UP_FAILED,
    string
>;

export type SignOut = Action<AUTH_ACTION_TYPES.SIGN_OUT>;


export const signInStart = withMatcher(
    (email: string, password: string, registration_id: string, onComplete: () => void): SignInStart =>
        createAction(AUTH_ACTION_TYPES.SIGN_IN_START, { email, password, registration_id, onComplete })
);

export const signInSuccess = withMatcher(
    (accessToken: string): SignInSuccess =>
        createAction(AUTH_ACTION_TYPES.SIGN_IN_SUCCESS, { accessToken })
);

export const signInFailed = withMatcher(
    (error: string): SignInFailed =>
        createAction(AUTH_ACTION_TYPES.SIGN_IN_FAILED, error)
);

export const getUserProfileStart = withMatcher(
    (accessToken: string): GetUserProfileStart =>
        createAction(AUTH_ACTION_TYPES.GET_USER_PROFILE_START, { accessToken })
);

export const getUserProfileSuccess = withMatcher(
    (accessToken: string, userProfile: User): GetUserProfileSuccess =>
        createAction(AUTH_ACTION_TYPES.GET_USER_PROFILE_SUCCESS, { accessToken, userProfile })
);

export const getUserProfileFailed = withMatcher(
    (error: string): GetUserProfileFailed =>
        createAction(AUTH_ACTION_TYPES.GET_USER_PROFILE_FAILED, error)
);

export const refreshUserProfile = withMatcher(
    (accessToken: string): RefreshUserProfile =>
        createAction(AUTH_ACTION_TYPES.REFRESH_USER_PROFILE, { accessToken })
);

export const getRandomUsernameStart = withMatcher(
    (): GetRandomUsernameStart => createAction(AUTH_ACTION_TYPES.GET_RANDOM_USERNAME_START)
);

export const getRandomUsernameSuccess = withMatcher(
    (username: string): GetRandomUsernameSuccess =>
        createAction(AUTH_ACTION_TYPES.GET_RANDOM_USERNAME_SUCCESS, { username })
);

export const getRandomUsernameFailed = withMatcher(
    (error: string): GetRandomUsernameFailed =>
        createAction(AUTH_ACTION_TYPES.GET_RANDOM_USERNAME_FAILED, error)
);

export const setFirstSurveyCompletedStart = withMatcher(
    (): SetFirstSurveyCompletedStart =>
        createAction(AUTH_ACTION_TYPES.SET_FIRST_SURVEY_COMPLETED_START)
);

export const setFirstSurveyCompletedSuccess = withMatcher(
    (accessToken: string): SetFirstSurveyCompletedSuccess =>
        createAction(AUTH_ACTION_TYPES.SET_FIRST_SURVEY_COMPLETED_SUCCESS, { accessToken })
);

export const setFirstSurveyCompletedFailed = withMatcher(
    (error: string): SetFirstSurveyCompletedFailed =>
        createAction(AUTH_ACTION_TYPES.SET_FIRST_SURVEY_COMPLETED_FAILED, error)
);

export const setLastSurveyCompletedStart = withMatcher(
    (): SetLastSurveyCompletedStart =>
        createAction(AUTH_ACTION_TYPES.SET_LAST_SURVEY_COMPLETED_START)
);

export const setLastSurveyCompletedSuccess = withMatcher(
    (accessToken: string): SetLastSurveyCompletedSuccess =>
        createAction(AUTH_ACTION_TYPES.SET_LAST_SURVEY_COMPLETED_SUCCESS, { accessToken })
);

export const setLastSurveyCompletedFailed = withMatcher(
    (error: string): SetLastSurveyCompletedFailed =>
        createAction(AUTH_ACTION_TYPES.SET_LAST_SURVEY_COMPLETED_FAILED, error)
);

export const setBirthOccurredStart = withMatcher(
    (onComplete: () => void): SetBirthOccurredStart =>
        createAction(AUTH_ACTION_TYPES.SET_BIRTH_OCCURRED_START, { onComplete })
);

export const setBirthOccurredSuccess = withMatcher(
    (accessToken: string): SetBirthOccurredSuccess =>
        createAction(AUTH_ACTION_TYPES.SET_BIRTH_OCCURRED_SUCCESS, { accessToken })
);

export const setBirthOccurredFailed = withMatcher(
    (error: string): SetBirthOccurredFailed =>
        createAction(AUTH_ACTION_TYPES.SET_BIRTH_OCCURRED_FAILED, error)
);

export const signUpStart = withMatcher(
    (username: string, pin: string, qualification: string, domicile_province: string, age_range: string, initial_pregnancy_week: number | null, birth_date: string | null, onComplete: (
        { pregnancy_week, post_birth_week }:
            { pregnancy_week: number | null, post_birth_week: number | null; }) => void): SignUpStart =>
        createAction(AUTH_ACTION_TYPES.SIGN_UP_START, { username, pin, qualification, domicile_province, age_range, initial_pregnancy_week, birth_date, onComplete })
);

export const signUpSuccess = withMatcher(
    (): SignUpSuccess =>
        createAction(AUTH_ACTION_TYPES.SIGN_UP_SUCCESS)
);

export const signUpFailed = withMatcher(
    (error: string): SignUpFailed =>
        createAction(AUTH_ACTION_TYPES.SIGN_UP_FAILED, error)
);

export const signOut = withMatcher(
    (): SignOut => createAction(AUTH_ACTION_TYPES.SIGN_OUT)
);
