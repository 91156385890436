export const API_PATH = '/api/backend/';
export const BACKEND_CLIENT_ID = 'it.burlo.primimillegiorni';
export const BACKEND_GRANT_TYPE = 'password';
export const BACKEND_BASE_URL = process.env.REACT_APP_BASE_URL + API_PATH;

export const LOGIN_URL = `${BACKEND_BASE_URL}o/token/`;
export const USER_PROFILE_URL = `${BACKEND_BASE_URL}users/me/`;
export const RANDOM_USERNAME_URL = `${BACKEND_BASE_URL}users/get_random_username/`;
export const SIGN_UP_URL = `${BACKEND_BASE_URL}users/sign_up/`;
export const NEWS_URL = `${BACKEND_BASE_URL}news/`;
export const NEWS_CATEGORIES_URL = `${BACKEND_BASE_URL}news_categories/`;
export const TOGGLE_NEWS_FAVORITES_URL = `${BACKEND_BASE_URL}users/toggle_favorite_news/`;
export const POLLUTION_DATA_URL = `/media/esposoma.json`;
export const ITALIAN_PROVINCES_GEOJSON_URL = `/limits_IT_provinces.geojson`;
export const SETTINGS_URL = `${BACKEND_BASE_URL}settings/`;
export const FAQ_URL = `${BACKEND_BASE_URL}faqs/`;
export const FIST_SURVEY_COMPLETED_URL = `${BACKEND_BASE_URL}users/set_first_survey_completed/`;
export const LAST_SURVEY_COMPLETED_URL = `${BACKEND_BASE_URL}users/set_last_survey_completed/`;
export const BIRTH_OCCURRED_URL = `${BACKEND_BASE_URL}users/set_birth_occurred/`;